<template>
  <ul class="menu-nav">

    <router-link
      :to="{ name: 'pages-by-path', params: { path: homePath } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/terms.svg" />
          <span class="menu-text">{{ homeName }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/brand-assets"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/assets.svg" />
          <span class="menu-text">Brand Assets</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/power-brands"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/assets.svg" />
          <span class="menu-text">Power Brands</span>
        </a>
      </li>
    </router-link>

    <router-link
      :to="{ name: 'pages-by-path', params: { path: tierraPath } }"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/terms.svg" />
          <span class="menu-text">{{ tierraName }}</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/showcase"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/showcase.svg" />
          <span class="menu-text">Showcase</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/terms-and-conditions"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <img class="menu-svg" src="media/svg/icons/Navigation/terms.svg" />
          <span class="menu-text">Terms &amp; Conditions</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  data() {
    return {
      homePath: process.env.VUE_APP_HOME_PATH,
      tierraPath: process.env.VUE_APP_TIERRA_PATH,
    }
  },
  computed: {
    homeName() {
      return 'Homepage - Lavazza';
    },
    tierraName() {
      return ('Homepage - ' + this.tierraPath).replace(/ [\w]/g, a => a.toLocaleUpperCase()).trim();
    },
  },
};
</script>
